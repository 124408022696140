const ConditionalInput = (el) => {
  const conditionInput = document.querySelector(
    `[name="${el.dataset.conditionInput}"]`
  )
  const conditionValues = el.dataset.conditionValues.split(",")

  const updateConditionalInput = () => {
    const condition = conditionValues.includes(conditionInput.value)
    el.toggleAttribute("disabled", !condition)
    el.closest(".field-wrapper").classList.toggle("hidden", !condition)
  }

  updateConditionalInput()
  conditionInput.addEventListener("change", updateConditionalInput)
}

export default ConditionalInput
