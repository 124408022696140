export const prefix = "cb"
export const iconName = "bluesky"
export const width = 13
export const height = 13
export const ligatures = []
export const unicode = "e000"

export const svgPathData =
  "M2.84 1.58C4.32 2.69 5.92 4.94 6.5 6.16c0.58-1.21 2.18-3.46 3.66-4.58c1.07-0.8 2.8-1.42 2.8 0.55c0 0.39-0.23 3.31-0.36 3.79c-0.46 1.65-2.17 2.06-3.87 1.78c2.61 0.44 3.28 1.92 1.84 3.39c-2.73 2.8-3.92-0.7-4.22-1.6l0 0c-0.04-0.12-0.07-0.2-0.08-0.2s-0.04 0.08-0.08 0.2l0 0c-0.3 0.9-1.5 4.4-4.22 1.6c-1.43-1.47-0.77-2.95 1.84-3.39c-1.7 0.28-3.41-0.13-3.87-1.78C0.27 5.44 0.04 2.52 0.04 2.13c0-1.97 1.73-1.35 2.8-0.55z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const cbBluesky = definition
